import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { AddInquiryDialog } from "../components/customUi/add-inquiry-dialog";
import { useEffect, useRef, useState } from "react";
import { inqActive, inqDisable, leftSvg, rightSvg } from "../svgs/svgs";
import { useDispatch, useSelector } from "react-redux";
import { getInquiry } from "../redux/actions/inquiryAction";
import { ChevronRight } from "lucide-react";

const InquiryRecord = () => {
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [listView, setListView] = useState(true);
    const user = useSelector((state) => state.userReducer.user);
    const inquiry = useSelector((state) => state.inquiryReducer.inquiry);
    const dispatch = useDispatch();
    const scrollableDivRef = useRef(null);

    useEffect(() => {
        const scrollToBottom = () => {
            if (scrollableDivRef.current) {
                scrollableDivRef.current.scrollTo({
                    top: scrollableDivRef.current.scrollHeight,
                    behavior: "smooth",
                });
            }
        };
        setTimeout(scrollToBottom, 100);
    }, [inquiry]);

    useEffect(() => {
        dispatch(getInquiry(user?.teamId));
    }, [user?.teamId, dispatch]);

    const handlePrevDay = () => {
        const prevDate = new Date(currentDate);
        prevDate.setDate(prevDate.getDate() - 1);
        setCurrentDate(prevDate);
    };

    const handleNextDay = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(nextDate.getDate() + 1);
        setCurrentDate(nextDate);
    };

    const getCurrentTimePeriod = () => {
        const currentHour = currentDate.getHours();
        if (currentHour >= 5 && currentHour < 12) {
            return "Good morning!";
        } else if (currentHour >= 12 && currentHour < 18) {
            return "Good afternoon!";
        } else {
            return "Good evening!";
        }
    };

    const getCurrentDate = () => {
        const options = {
            month: "short",
            day: "numeric",
            year: "numeric"
        };
        return currentDate.toLocaleDateString("en-US", options);
    };

    function formatDateToDDMMYYYY(dateStr) {
        const dateObj = new Date(dateStr);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // Group inquiries by createdAt date
    const groupedInquiries = {};
    if (listView) {
        inquiry.forEach(elm => {
            const createdAtDate = formatDateToDDMMYYYY(elm.createdAt);
            if (!groupedInquiries[createdAtDate]) {
                groupedInquiries[createdAtDate] = [];
            }
            groupedInquiries[createdAtDate].push(elm);
        });
    }

    let displayIndexMap = {};

    return (
        <div className="h-[100vh]">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate("/workspace")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">Inquiry-record</div>
                </div>
                <div onClick={() => setListView(!listView)}>
                    {!listView ? inqDisable : inqActive}
                </div>
            </div>
            <div ref={scrollableDivRef} className="h-[92%] overflow-auto">
                {!listView && (
                    <div className="px-10 flex justify-center mb-3 gap-x-10 items-center">
                        <div className="mt-2 active:bg-blue-100 rounded-full" onClick={handlePrevDay}>{leftSvg}</div>
                        <div className="select-none">
                            <div className="text-center text-xl font-semibold mt-3">
                                {getCurrentTimePeriod()}
                            </div>
                            <div className="text-center">
                                {getCurrentDate()},{" "}
                                {currentDate.toLocaleDateString("en-US", { weekday: "long" })}
                            </div>
                        </div>
                        <div className={`mt-2 ${currentDate.toDateString() === new Date().toDateString() ? 'opacity-0 pointer-events-none' : 'active:bg-blue-100'} rounded-full`} onClick={handleNextDay}>
                            {rightSvg}
                        </div>
                    </div>
                )}

                <div className="px-3 py-2">
                    {listView === false && inquiry?.map((elm, index) => {
                        const createdAtDate = new Date(elm.createdAt);
                        const isSameDate = createdAtDate.toDateString() === currentDate.toDateString();

                        if (isSameDate) {
                            // Check if display index for this date exists, if not, initialize it to 1
                            if (!displayIndexMap[currentDate.toDateString()]) {
                                displayIndexMap[currentDate.toDateString()] = 1;
                            }

                            const displayIndex = displayIndexMap[currentDate.toDateString()]; // Get display index for this date
                            displayIndexMap[currentDate.toDateString()]++; // Increment display index for this date

                            return (
                                <div onClick={() => navigate(`/inquiry-details/${displayIndex.toString().padStart(2, '0')}/${elm._id}`)} className="flex mb-3 justify-between gap-x-2 items-center" key={index}>
                                    <div className="flex justify-center items-center sc h-[60px] font-semibold rounded-[8px] w-[74px]">
                                        #{displayIndex.toString().padStart(2, '0')}
                                    </div>
                                    <div className="flex justify-between w-full items-center sc h-[60px] rounded-[8px] px-3">
                                        <div>
                                            <div className="font-semibold text-lg">{elm.customerName}</div>
                                            <div className="font-[500] text-[#191B24] text-[0.875rem]">{elm.project}</div>
                                        </div>
                                        <div className="h-[42px] text-white w-[42px] rounded-full flex justify-center items-center bg-[#0066FA]">
                                            <ChevronRight color="#fff" />
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}

                    {listView && Object.keys(groupedInquiries).map(date => (
                        <div key={date}>
                            <div className="text-center my-[2rem] relative border-t border-[#FAF8FF]">
                                <div className=" absolute left-[50%] top-[50%] px-3 bg-[#5A72B5] translate-x-[-50%] translate-y-[-50%] rounded-[8px] text-white">{date}</div>
                            </div>
                            {groupedInquiries[date].map((elm, index) => (
                                <div onClick={() => navigate(`/inquiry-details/${(index + 1).toString().padStart(2, '0')}/${elm._id}`)} className="flex mb-3 justify-between gap-x-2 items-center" key={index}>
                                    <div className="flex justify-center items-center sc h-[60px] font-semibold rounded-[8px] w-[74px]">
                                        #{(index + 1).toString().padStart(2, '0')}
                                    </div>
                                    <div className="flex justify-between w-full items-center sc h-[60px] rounded-[8px] px-3">
                                        <div>
                                            <div className="font-semibold text-lg">{elm.customerName}</div>
                                            <div className="font-[500] text-[#191B24] text-[0.875rem]">{elm.project}</div>
                                        </div>
                                        <div className="h-[42px] text-white w-[42px] rounded-full flex justify-center items-center bg-[#0066FA]">
                                            <ChevronRight color="#fff" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

            </div>
            <div className={`${""} fixed bottom-40 lg:bottom-36 right-20 lg:right-[42.5%]`}>
                <AddInquiryDialog currentDate={currentDate} />
            </div>
        </div>
    );
}

export default InquiryRecord;