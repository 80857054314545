import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { addPayment, deletePayment, getPayment } from "../redux/actions/paymentAction";
import { updateFlatById } from "../redux/actions/flatAction";
import { Trash2 } from "lucide-react";
import toast from "react-hot-toast";
import { dateSvg, editSvg } from "../svgs/svgs"

const PaymentDetails = ({ setisLoading }) => {
    const { id, projectId } = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [progress, setProgress] = useState(0);
    const [flat, setFlat] = useState();
    const dispatch = useDispatch();
    const paymentArr = useSelector((state) => state.paymentReducer.payment);
    const user = useSelector((state) => state.userReducer.user);
    const [amountType, setAmountType] = useState("cash");
    // let [payment, setPayment] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');
    const [showAddOutstanding, setShowAddOutstanding] = useState(false);
    const [swipedIndices, setSwipedIndices] = useState(new Set());

    useEffect(() => {
        setSwipedIndices(new Set());
    }, [amountType]);

    const handleTouchStart = (e, index) => {
        const touch = e.touches[0];
        const item = e.currentTarget.dataset;
        item.startX = touch.clientX;
    };

    const handleTouchMove = (e, index) => {
        const touch = e.touches[0];
        const item = e.currentTarget.dataset;
        const currentX = touch.clientX;

        if (item.startX - currentX > 50) {
            // Swiped left
            setSwipedIndices(new Set([index]));
        } else if (currentX - item.startX > 50) {
            // Swiped right
            setSwipedIndices(prev => {
                const newSet = new Set(prev);
                newSet.delete(index);
                return newSet;
            });
        }
    };

    const handleSwipeClick = (index) => {
        setSwipedIndices(prev => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
        });
    };

    useEffect(() => {
        dispatch(getPayment(id));
        !user?.superAdmin && setAmountType('cheque')
    }, [id, dispatch, user]);

    useEffect(() => {
        if (id) {
            getFunction(id);
        }
    }, [id]);

    useEffect(() => {
        flat?.loanStatus === undefined && setProgress(0);
        flat?.loanStatus === "Application" && setProgress(1);
        flat?.loanStatus === "Process" && setProgress(2);
        flat?.loanStatus === "Sanctioned" && setProgress(3);
    }, [flat]);

    const getFunction = (id) => {
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => {
                setFlat(res.data)
            })
            .catch((err) => console.log(err));
    };

    function convertPriceToIndianNumberingSystem(price) {
        const digits = price.toString().split("");

        digits.reverse();

        for (let i = 3; i < digits.length; i += 3) {
            digits.splice(i, 0, ",");
        }

        const indianPrice = digits.join("");
        const payment = indianPrice.split('').reverse().join('');

        return payment;
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);

        // Format the date part
        const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, add leading zero
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const handleDelete = (id, flatId) => {
        dispatch(deletePayment(id, flatId));
    }

    const renderOutstandingAmount = () => {
        if (amountType === "cash") {
            return (
                <>
                    {(flat?.outstandingAmountCash && !showAddOutstanding) && (
                        <div className="h-full relative">
                            <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                                <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                                    <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                        <div>
                                            <div>Outstanding:</div>
                                            <div className="flex items-center gap-x-2">
                                                ₹{convertPriceToIndianNumberingSystem(flat?.outstandingAmountCash)}
                                                <div onClick={() => setShowAddOutstanding(true)}>
                                                    {user?.superAdmin === true && editSvg}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>Receivable:</div>
                                            <div className="text-[#299F2D]">
                                                ₹{convertPriceToIndianNumberingSystem(
                                                    (flat?.outstandingAmountCash || 0) -
                                                    paymentArr
                                                        .filter(item => item.amountType === 'cash')
                                                        .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {paymentArr.filter(item => item.amountType === 'cash').length > 0 && (
                                        <div className="text-[1rem] mt-[1rem] font-[500]">
                                            {paymentArr.filter(item => item.amountType === 'cash').length} Payments
                                        </div>
                                    )}
                                    {paymentArr
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .filter((item) => item.amountType === 'cash')
                                        .map((item, index) => (
                                            <div key={index} className="mt-[1rem]">
                                                <div
                                                    className={`relative overflow-hidden py-[0.75rem] px-[1rem] bg-[#ECEDF9] w-full rounded-[0.375rem] transition-transform duration-300`}
                                                    data-start-x="0"
                                                    onTouchStart={(e) => handleTouchStart(e, item._id)}
                                                    onTouchMove={(e) => handleTouchMove(e, item._id)}
                                                    onClick={() => handleSwipeClick(item._id)}
                                                >
                                                    <div className="flex items-center w-full">
                                                        <div className="flex flex-wrap justify-between w-full items-center">
                                                            <div className="flex items-center gap-x-3">
                                                                <img
                                                                    src={item?.imageUrl}
                                                                    alt={item?.userName}
                                                                    className="h-[2rem] w-[2rem] rounded-full shrink-0"
                                                                />
                                                                <div>
                                                                    <div className="text-[1rem] font-[500]">{item?.userName}</div>
                                                                    <div className="text-sm break-words">{formatDateTime(item?.date)}</div>
                                                                </div>
                                                            </div>
                                                            <div className={`text-[#299F2D] text-[1rem] font-[500] transition-all duration-500`}>
                                                                +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`text-[#424655] text-[0.75rem] font-[400] transition-all duration-500 text-start mt-1`}>
                                                        {item?.remark}
                                                    </div>
                                                    <div
                                                        className={`absolute p-1 top-1/2 -translate-y-1/2 bg-[#0066FA] h-full flex items-center justify-center text-white right-0 w-[2.8rem] rounded-tr-[0.375rem] rounded-br-[0.375rem] ${swipedIndices.has(item._id)
                                                            ? 'translate-x-0 scale-100'
                                                            : 'translate-x-10 scale-0'
                                                            } transition-transform duration-500 origin-right`}
                                                    >
                                                        <DeletePayment item={item} handleDelete={handleDelete} user={user} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="absolute bottom-0 left-0 w-full">
                                {user?.superAdmin === true && (
                                    <AddEntry flat={flat} user={user} amountType={amountType} />
                                )}
                            </div>
                        </div>
                    )}
                    {(showAddOutstanding || !flat?.outstandingAmountCash) && (
                        <div>
                            {user?.superAdmin === true && (
                                <AddOutstandingAmount
                                    type={amountType}
                                    flat={flat}
                                    setisLoading={setisLoading}
                                    getFunction={getFunction}
                                    isEdit={showAddOutstanding && flat?.outstandingAmountCash}
                                    editAmount={flat?.outstandingAmountCash}
                                    setShowAddOutstanding={setShowAddOutstanding}
                                />
                            )}
                        </div>
                    )}
                </>
            );
        }
        if (amountType === "cheque") {
            return (
                <>
                    {(flat?.outstandingAmountCheque && !showAddOutstanding) && (
                        <div className="h-full relative">
                            <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                                <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                                    <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                        <div>
                                            <div>Outstanding:</div>
                                            <div className="flex items-center gap-x-2">
                                                ₹{convertPriceToIndianNumberingSystem(flat?.outstandingAmountCheque)}
                                                <div onClick={() => setShowAddOutstanding(true)}>
                                                    {user?.superAdmin && editSvg}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>Receivable:</div>
                                            <div className="text-[#299F2D]">
                                                ₹{convertPriceToIndianNumberingSystem(
                                                    (flat?.outstandingAmountCheque || 0) -
                                                    paymentArr
                                                        .filter(item => item.amountType === 'cheque')
                                                        .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {paymentArr.filter(item => item.amountType === 'cheque').length > 0 && (
                                        <div className="text-[1rem] mt-[1rem] font-[500]">
                                            {paymentArr.filter(item => item.amountType === 'cheque').length} Payments
                                        </div>
                                    )}
                                    {paymentArr
                                        .filter((item) => item.amountType === 'cheque')
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .map((item, index) => (
                                            <div key={index} className="mt-[1rem]">
                                                <div
                                                    className="relative flex items-center py-[0.75rem] px-[1rem] bg-[#ECEDF9] w-full overflow-hidden rounded-[0.375rem]"
                                                    data-start-x="0"
                                                    onTouchStart={(e) => handleTouchStart(e, item._id)}
                                                    onTouchMove={(e) => handleTouchMove(e, item._id)}
                                                    onClick={() => handleSwipeClick(item._id)}
                                                >
                                                    <div className="flex flex-wrap justify-between w-full items-center">
                                                        <div className="flex items-center gap-x-3">
                                                            <img
                                                                src={item?.imageUrl}
                                                                alt={index}
                                                                className="h-[2rem] w-[2rem] rounded-full shrink-0"
                                                            />
                                                            <div>
                                                                <div className="text-[1rem] font-[500]">{item?.userName}</div>
                                                                <div className="text-sm">{formatDateTime(item?.date)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="text-[#299F2D] text-[1rem] font-[500]">
                                                            +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`absolute p-1 top-1/2 -translate-y-1/2 bg-[#0066FA] h-full flex items-center justify-center text-white right-0 w-[2.8rem] rounded-tr-[0.375rem] rounded-br-[0.375rem] ${swipedIndices.has(item._id)
                                                            ? 'translate-x-0 scale-100'
                                                            : 'translate-x-10 scale-0'
                                                            } transition-transform duration-500 origin-right`}
                                                    >
                                                        <DeletePayment item={item} handleDelete={handleDelete} user={user} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="absolute bottom-0 left-0 w-full">
                                {user?.superAdmin === true && (
                                    <AddEntry
                                        flat={flat}
                                        user={user}
                                        amountType={amountType}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {(showAddOutstanding || !flat?.outstandingAmountCheque) && (
                        <>
                            {user?.superAdmin === true && (
                                <AddOutstandingAmount
                                    type={amountType}
                                    flat={flat}
                                    setisLoading={setisLoading}
                                    getFunction={getFunction}
                                    isEdit={showAddOutstanding && flat?.outstandingAmountCheque}
                                    editAmount={flat?.outstandingAmountCheque}
                                    setShowAddOutstanding={setShowAddOutstanding}
                                />
                            )}
                        </>
                    )}
                </>
            )
        }

        const AmountDisplay = ({
            title,
            amount,
            createdAt,
            flatId,
        }) => {
            // Map title to field name
            const getTitleField = (title) => {
                const fieldMap = {
                    'Dastavej': 'outstandingAmountOtherDastavej',
                    'Maintenance': 'outstandingAmountOtherMaintenance',
                    'Developing': 'outstandingAmountOtherDeveloping',
                    'GST': 'outstandingAmountOtherGst',
                    'Other': 'outstandingAmountOther'
                };
                return fieldMap[title];
            };

            const handleDeleteOther = async () => {
                try {
                    const field = getTitleField(title);

                    const response = await axios.put(`${baseUrl}/api/flat/delete-outstanding/${flatId}`, {
                        field
                    });

                    if (response.data.success) {
                        toast.success('Amount deleted successfully');
                        getFunction(id);
                    } else {
                        toast.error('Failed to delete outstanding amount');
                    }
                } catch (error) {
                    console.error('Error deleting outstanding amount:', error);
                    toast.error('Error deleting outstanding amount');
                }
            };

            return (
                <div className="mt-4">
                    <div className="relative flex items-center py-3 px-4 bg-[#ECEDF9] rounded">
                        <div className="flex flex-wrap justify-between w-full items-center">
                            <div className="flex items-center gap-x-3">
                                <div>
                                    <div className="text-lg font-medium">{title}</div>
                                    <div className="text-sm">{formatDateTime(createdAt)}</div>
                                </div>
                            </div>
                            <div className="text-green-600 text-xl font-medium">
                                +₹{convertPriceToIndianNumberingSystem(amount)}
                            </div>
                        </div>
                        {user?.superAdmin === true && (
                            <Dialog>
                                <DialogTrigger>
                                    <div className="absolute -top-2 p-1 -right-2 bg-white rounded-full text-red-500">
                                        <Trash2 size={20} />
                                    </div>
                                </DialogTrigger>
                                <DialogContent className="text-center w-11/12 rounded">
                                    <div className="text-xl font-semibold">Are you sure</div>
                                    <div>You want to delete this {title} outstanding amount?</div>
                                    <div className="flex justify-between items-center">
                                        <DialogClose>
                                            <Button
                                                variant="primary"
                                                className="bg-indigo-500 text-white font-semibold rounded"
                                            >
                                                Cancel
                                            </Button>
                                        </DialogClose>
                                        <DialogClose>
                                            <Button
                                                variant="primary"
                                                className="bg-red-500 text-white font-semibold rounded"
                                                onClick={handleDeleteOther}
                                            >
                                                Delete
                                            </Button>
                                        </DialogClose>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        )}
                    </div>
                </div>
            );
        };

        if (amountType === "other") {
            const outstandingAmounts = [
                {
                    title: "Dastavej",
                    amount: flat?.outstandingAmountOtherDastavej?.amount,
                    createdAt: flat?.outstandingAmountOtherDastavej?.createdAt
                },
                {
                    title: "Maintenance",
                    amount: flat?.outstandingAmountOtherMaintenance?.amount,
                    createdAt: flat?.outstandingAmountOtherMaintenance?.createdAt
                },
                {
                    title: "Developing",
                    amount: flat?.outstandingAmountOtherDeveloping?.amount,
                    createdAt: flat?.outstandingAmountOtherDeveloping?.createdAt
                },
                {
                    title: "GST",
                    amount: flat?.outstandingAmountOtherGst?.amount,
                    createdAt: flat?.outstandingAmountOtherGst?.createdAt
                },
                {
                    title: "Other",
                    amount: flat?.outstandingAmountOther?.amount,
                    createdAt: flat?.outstandingAmountOther?.createdAt
                }
            ];

            const hasAnyOutstandingAmount = outstandingAmounts.some(item => item.amount);

            return (
                <>
                    {(!showAddOutstanding && hasAnyOutstandingAmount) && (
                        <div className="h-full relative">
                            <div className="px-3 py-2 h-[calc(100%-70px)] overflow-y-auto">
                                <div className="border py-[0.75rem] px-[1rem] bg-[#F7F2FA] rounded-[0.375rem]">
                                    <div className="flex justify-between text-[1.25rem]" style={{ lineHeight: '150%' }}>
                                        <div>
                                            <div>Outstanding:</div>
                                            <div className="flex items-center gap-x-2">
                                                ₹{convertPriceToIndianNumberingSystem(
                                                    outstandingAmounts.reduce((sum, item) => sum + item.amount, 0)
                                                )}
                                                <div onClick={() => setShowAddOutstanding(true)}>
                                                    {user?.superAdmin === true && editSvg}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>Receivable:</div>
                                            <div className="text-[#299F2D]">
                                                ₹{convertPriceToIndianNumberingSystem(
                                                    (outstandingAmounts.reduce((sum, item) => sum + item.amount, 0) || 0) -
                                                    paymentArr
                                                        .filter(item => item.amountType === 'other')
                                                        .reduce((sum, item) => sum + (item?.amount || 0), 0)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-[1rem] px-[1rem]">
                                    {outstandingAmounts?.map((item, index) => {
                                        return (
                                            <div key={index} className="flex justify-between text-[0.875rem]">
                                                <div>{item?.title}</div>
                                                <div>
                                                    {item.amount ? (
                                                        <div>
                                                            ₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                                        </div>
                                                    ) : (
                                                        <div>-</div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div>
                                    {paymentArr.filter(item => item.amountType === 'other').length > 0 && (
                                        <div className="text-[1rem] mt-[1rem] font-[500]">
                                            {paymentArr.filter(item => item.amountType === 'other').length} Payments
                                        </div>
                                    )}
                                    {paymentArr
                                        .filter((item) => item.amountType === 'other')
                                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                                        .map((item, index) => (
                                            <div key={index} className="mt-[1rem] flex">
                                                <div
                                                    className="relative flex items-center py-[0.75rem] px-[1rem] bg-[#ECEDF9] w-full overflow-hidden rounded-[0.375rem]"
                                                    data-start-x="0"
                                                    onTouchStart={(e) => handleTouchStart(e, item._id)}
                                                    onTouchMove={(e) => handleTouchMove(e, item._id)}
                                                    onClick={() => handleSwipeClick(item._id)}
                                                >
                                                    <div className="flex flex-wrap justify-between w-full items-center">
                                                        <div className="flex items-center gap-x-3">
                                                            <img
                                                                src={item?.imageUrl}
                                                                alt={index}
                                                                className="h-[2rem] w-[2rem] rounded-full shrink-0"
                                                            />
                                                            <div>
                                                                <div className="text-[1rem] font-[500]">{item?.userName}</div>
                                                                <div className="text-sm break-words">{formatDateTime(item?.date)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="text-[#299F2D] text-[1rem] font-[500]">
                                                            +₹{convertPriceToIndianNumberingSystem(item?.amount)}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`absolute p-1 top-1/2 -translate-y-1/2 bg-[#0066FA] h-full flex items-center justify-center text-white right-0 w-[2.8rem] rounded-tr-[0.375rem] rounded-br-[0.375rem] ${swipedIndices.has(item._id)
                                                            ? 'translate-x-0 scale-100'
                                                            : 'translate-x-10 scale-0'
                                                            } transition-transform duration-500 origin-right`}
                                                    >
                                                        <DeletePayment item={item} handleDelete={handleDelete} user={user} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="absolute bottom-0 left-0 w-full">
                                {user?.superAdmin === true && (
                                    <AddEntry
                                        flat={flat}
                                        user={user}
                                        amountType={amountType}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {(showAddOutstanding || !hasAnyOutstandingAmount) && (
                        <>
                            {user?.superAdmin === true && (
                                <AddOutstandingAmount
                                    type={amountType}
                                    flat={flat}
                                    setisLoading={setisLoading}
                                    getFunction={getFunction}
                                    isEdit={showAddOutstanding && hasAnyOutstandingAmount}
                                    editAmountObj={{
                                        dastavej: flat?.outstandingAmountOtherDastavej?.amount,
                                        maintenance: flat?.outstandingAmountOtherMaintenance?.amount,
                                        developing: flat?.outstandingAmountOtherDeveloping?.amount,
                                        gst: flat?.outstandingAmountOtherGst?.amount,
                                        other: flat?.outstandingAmountOther?.amount,
                                    }}
                                    setShowAddOutstanding={setShowAddOutstanding}
                                />
                            )}
                        </>
                    )}
                </>
            )
        }
    };

    return (
        <div className="h-[100vh] overflow-auto">
            <div className="h-[8vh] flex justify-between items-center ps-1 pe-3">
                <div className="flex justify-start items-center gap-x-1">
                    <div>
                        <div className="flex p-2 rounded-full justify-center items-center">
                            <BiArrowBack
                                onClick={() => {
                                    navigate(`/flat-details/${id}/${projectId}?redirect=${redirect}`);
                                }}
                                size={24}
                            />
                        </div>
                    </div>
                    <div className="text-xl font-semibold cursor-pointer">{flat?.series}</div>
                </div>
            </div>
            <div className="h-[92vh]">
                <div className="border-b h-[5vh]">
                    <div className="flex justify-between items-center h-full">
                        {(user?.superAdmin === true || user?.role === "office" || user?.role === "admin") && (
                            <div
                                onClick={() => {
                                    setAmountType("cash")
                                    setShowAddOutstanding(false);
                                }}
                                className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "cash" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                            >
                                Cash
                            </div>
                        )}

                        <div
                            onClick={() => {
                                setAmountType("cheque")
                                setShowAddOutstanding(false)
                            }}
                            className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "cheque" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                        >
                            Cheque
                        </div>

                        <div
                            onClick={() => {
                                setAmountType("other")
                                setShowAddOutstanding(false)
                            }}
                            className={`flex justify-center items-center w-full h-full pb-3 ${amountType === "other" ? "border-b-[2px] border-[#0066FA]" : "hover:border-b-[2px] border-transparent"}`}
                        >
                            Other
                        </div>
                    </div>
                </div>
                <div className="h-[87vh]">
                    {renderOutstandingAmount()}
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;

const AddOutstandingAmount = ({ type, flat, setisLoading, getFunction, isEdit, editAmount, setShowAddOutstanding, editAmountObj }) => {
    // Separate states for each type
    const formatNumber = (val) => {
        const stringValue = String(val || "");
        return stringValue
            .replace(/\D/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    const [cashAmount, setCashAmount] = useState(isEdit ? formatNumber(editAmount) : "");
    const [chequeAmount, setChequeAmount] = useState(isEdit ? formatNumber(editAmount) : "");
    const [otherAmounts, setOtherAmounts] = useState(
        editAmountObj
            ? {
                dastavej: formatNumber(editAmountObj.dastavej || 0),
                maintenance: formatNumber(editAmountObj.maintenance || 0),
                developing: formatNumber(editAmountObj.developing || 0),
                gst: formatNumber(editAmountObj.gst || 0),
                other: formatNumber(editAmountObj.other || 0)
            }
            : {
                dastavej: "",
                maintenance: "",
                developing: "",
                gst: "",
                other: ""
            }
    );

    const dispatch = useDispatch();

    const handleOtherChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatNumber(value);
        setOtherAmounts(prev => ({
            ...prev,
            [name]: formattedValue
        }));
    };

    const handleMainChange = (e, setter) => {
        const formattedValue = formatNumber(e.target.value);
        setter(formattedValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (type === "other") {
            // Create separate entries for each type of amount with their timestamps
            const updateData = {
                outstandingAmountOtherDastavej: {
                    amount: otherAmounts.dastavej.replace(/,/g, ""),
                    createdAt: new Date()
                },
                outstandingAmountOtherMaintenance: {
                    amount: otherAmounts.maintenance.replace(/,/g, ""),
                    createdAt: new Date()
                },
                outstandingAmountOtherDeveloping: {
                    amount: otherAmounts.developing.replace(/,/g, ""),
                    createdAt: new Date()
                },
                outstandingAmountOtherGst: {
                    amount: otherAmounts.gst.replace(/,/g, ""),
                    createdAt: new Date()
                },
                outstandingAmountOther: {
                    amount: otherAmounts.other.replace(/,/g, ""),
                    createdAt: new Date()
                }
            };

            dispatch(updateFlatById(
                flat?._id,
                updateData,
                flat?.projectId,
                setisLoading,
                getFunction
            ));
        } else {
            const amount = type === "cash" ? cashAmount : chequeAmount;
            const numericValue = amount.replace(/,/g, "");
            const updateData = type === "cash"
                ? { outstandingAmountCash: numericValue }
                : { outstandingAmountCheque: numericValue };

            dispatch(updateFlatById(
                flat?._id,
                updateData,
                flat?.projectId,
                setisLoading,
                getFunction
            ));
        }
        setShowAddOutstanding(false);
    };

    const renderInput = (label, name) => (
        <div>
            <div className="mt-[1.5rem] mb-[0.5rem] text-[1.125rem]">{label}</div>
            <input
                type="text"
                className="w-full sc px-3 outline-none h-[50px]"
                placeholder="1,00,000"
                style={{ borderRadius: "5px" }}
                name={name}
                value={otherAmounts[name]}
                onChange={handleOtherChange}
            />
        </div>
    );

    return (
        <form className="h-full overflow-y-auto overflow-x-hidden px-2" onSubmit={handleSubmit}>
            <div className={`py-[1.5rem] px-[1.47rem] border border-[#727687] rounded-[0.5rem] ${type === "other" ? "mt-[2rem]" : "mt-[8rem]"}`}>
                <div className="text-[1.25rem] font-[500]">
                    {isEdit ? "Edit" : "Add initial"} Outstanding Amount
                </div>

                {type === "other" ? (
                    <>
                        {renderInput("Dastavej Amount", "dastavej")}
                        {renderInput("Maintenance Amount", "maintenance")}
                        {renderInput("Developing Amount", "developing")}
                        {renderInput("GST Amount", "gst")}
                        {renderInput("Other Amount", "other")}
                    </>
                ) : (
                    <div>
                        <div className="mt-[1.5rem] mb-[0.5rem] text-[1.125rem]">Outstanding Amount</div>
                        <input
                            type="text"
                            className="w-full sc px-3 outline-none h-[50px]"
                            placeholder="1,00,000"
                            style={{ borderRadius: "5px" }}
                            value={type === "cash" ? cashAmount : chequeAmount}
                            onChange={(e) => handleMainChange(e, type === "cash" ? setCashAmount : setChequeAmount)}
                        />
                    </div>
                )}

                <Button
                    type="submit"
                    variant={"primary"}
                    className="flex active:bg-opacity-70 gap-x-2 mt-3 text-[18px] h-[50px] py-[24px] text-white justify-center items-center plus w-full font-[500] rounded-[8px]"
                >
                    {isEdit ? "Edit" : "Add"}
                </Button>
            </div>
        </form>
    );
};

const AddEntry = ({ flat, user, amountType }) => {
    const [value, setValue] = useState("");
    const [date, setDate] = useState(null); // To store date as Date type
    const [remark, setRemark] = useState(null); // To store date as Date type
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const formatNumber = (val) => {
        return val
            .replace(/\D/g, "")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    const handleAmountChange = (e) => {
        const formattedValue = formatNumber(e.target.value);
        setValue(formattedValue);
    };

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        setDate(selectedDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const numericValue = value.replace(/,/g, "");

        const payment = {
            amount: numericValue,
            flatId: flat?._id,
            teamId: flat?.teamId,
            amountType: amountType,
            userName: user?.userName,
            imageUrl: user?.imageUrl,
            date: date,
            remark: remark
        };

        dispatch(addPayment(payment, flat?._id));
        setValue(""); // Reset the input field
        setDate(null); // Reset the date field
        setRemark(null);
        setIsDialogOpen(false);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setValue(""); // Reset the input field
        setDate(null); // Reset the date field
    };

    return (
        <div className="bg-[#F3EDF7] py-[0.75rem] flex justify-center lg:max-w-[500px] h-[70px] pb-[1vh]">
            <Dialog
                open={isDialogOpen}
                onOpenChange={(open) => {
                    if (!open) handleDialogClose();
                }}
            >
                <DialogTrigger>
                    <button
                        className="bg-[#299F2D] py-[0.75rem] px-[2.25rem] flex items-center gap-x-2 rounded-[0.375rem] h-full"
                        onClick={() => setIsDialogOpen(true)}
                    >
                        <div className="h-[19px] w-[19px] rounded-full bg-white flex justify-center items-center text-[1rem]">
                            <span className="pb-[0.10rem] text-[#299f2d]">+</span>
                        </div>
                        <div className="text-[1.125rem] text-white">IN</div>
                    </button>
                </DialogTrigger>
                <DialogContent
                    className="top-[27%] w-[95vw]"
                    style={{ borderRadius: "10px" }}
                >
                    <DialogTitle>Add Entry (+)</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="">Amount*</label>
                        <input
                            type="text"
                            className="w-full sc px-3 outline-none h-[50px]"
                            placeholder="1,00,000"
                            style={{ borderRadius: "5px" }}
                            name="customerName"
                            value={value}
                            onChange={handleAmountChange}
                        />
                        <label htmlFor="" className="mt-[1.5rem]">Date*</label>
                        <div className="relative">
                            <input
                                type="date"
                                id="entryDate"
                                className="w-full sc px-3 outline-none h-[50px] border border-gray-300 pl-4 pr-12 bg-gray-100 text-gray-700"
                                placeholder="Select due Date"
                                style={{ borderRadius: "5px" }}
                                name="entryDate"
                                onChange={handleDateChange}
                            />
                            <span className="absolute inset-y-0 right-3 flex items-center">
                                {dateSvg}
                            </span>
                        </div>
                        <label className="mt-[1.5rem]" htmlFor="">Remark</label>
                        <input
                            type="text"
                            className="w-full sc px-3 outline-none h-[50px]"
                            placeholder="Example of remark"
                            style={{ borderRadius: "5px" }}
                            name="remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="flex active:bg-opacity-70 gap-x-2 text-[18px] h-[50px] py-4 mt-4 text-white justify-center items-center w-full font-[500] bg-[#299F2D]"
                            style={{ borderRadius: "8px" }}
                        >
                            Add
                        </button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const DeletePayment = ({ item, handleDelete, user }) => {
    if (!user?.superAdmin) {
        return null;
    }

    return (
        <Dialog>
            <DialogTrigger>
                <div className="absolute p-1 top-1/2 -translate-y-1/2 bg-[#0066FA] h-full flex items-center justify-center text-white right-0 w-[2.8rem] rounded-tr-[0.375rem] rounded-br-[0.375rem]">
                    <Trash2 size={20} />
                </div>
            </DialogTrigger>
            <DialogContent
                className=" text-center w-[95%] rounded-[5px]"
            >
                <div className=" text-xl font-semibold">Are you sure</div>
                <div>You want to delete this</div>
                <div className=" flex justify-between items-center">
                    <DialogClose>
                        <Button
                            variant="primary"
                            className=" bg-indigo-500 text-white font-semibold"
                            style={{ borderRadius: "5px" }}
                        >
                            Cancel
                        </Button>
                    </DialogClose>
                    <DialogClose>
                        <Button
                            variant="primary"
                            className=" bg-red-500 text-white font-semibold rounded-[5px]"
                            onClick={() => handleDelete(item?._id, item?.flatId)}
                        >
                            Delete
                        </Button>
                    </DialogClose>
                </div>
            </DialogContent>
        </Dialog>
    );
};